<template>
  <fragment>
    <div v-if="requestSent" class="alert alert-success">
      Thank You! Your password has been successfully reset. Please
      <router-link to="/login">click here to login</router-link>.
    </div>

    <div v-if="!isTokenValid" class="alert alert-danger">
      Oops! This reset token is either invalid or has expired; please
      <router-link to="/forgotpassword">click here</router-link> to request a new reset token.
    </div>

    <form class="d-flex flex-column align-items-center" v-on:submit.prevent="onSubmit">
      <p class="w-100 text-left">Password Reset</p>

      <div class="form-group control">
        <label for="password" class="col-md-3 control-label">Password</label>
        <input id="password" name="password" type="password" class="form-control" :class="{ 'is-invalid': errors.has('password') }"
          autocomplete="off" v-validate="{ required: true, min:8, max:50 }" v-model="resetPassword.password" ref="password" data-vv-as="Password" />
        <span class="invalid-feedback">{{ errors.first('password') }}</span>
      </div>

      <div class="form-group control">
        <label for="passwordConfirm" class="col-md-3 control-label">Confirm</label>
        <input id="passwordConfirm" name="passwordConfirm" type="password" class="form-control" :class="{ 'is-invalid': errors.has('passwordConfirm') }"
          autocomplete="off" v-validate="{ required: true, confirmed: 'password' }" data-vv-as="Password Confirm" />
        <span class="invalid-feedback">{{ errors.first('passwordConfirm') }}</span>
      </div>

      <button type="submit" class="btn btn-success ld-ext-right" :class="{ 'running' : isProcessing }" :disabled="isProcessing">
        Submit
        <div class="ld ld-ring ld-spin"></div>
      </button>

      <router-link to="/login" class="forgot w-100 text-center d-block" role="button">Back To Login</router-link>
    </form>
  </fragment>
</template>

<script>
import { UserService } from "@/services/"

export default {
  name: 'password-reset',
  data: function() {
    return {
      isProcessing: false,
      isTokenValid: true,
      requestSent: false,
      resetPassword: {
        token: null,
        password: null
      }
    };
  },
  methods: {
    async onSubmit() {
      this.isProcessing = false;
      this.isTokenValid = true;

      var isFormValid = await this.$validator.validate();
      if (!isFormValid) {
        return;
      }

      return UserService.resetPassword(this.resetPassword)
        .then(() => {
          this.isProcessing = false;
          this.requestSent = true
        })
        .catch(err => {
          this.isProcessing = false;
          if (err.response.data.message && err.response.data.modelState) {
            this.isTokenValid = false;
          }
        });
    }
  },
  mounted() {
    this.resetPassword.token = this.$route.params.token;
  }
};
</script>
